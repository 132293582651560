import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import Img from "gatsby-image"

export const AboutPageTemplate = ({
  heroImage,
  title,
  intro,
  description,
  image
}) => (
  <>
    <PageHero Title={title} Image={heroImage}/> 
    <div className="pageContent pageContent__noAnimate contact">
      <div className="pageContent__inner"> 
        <article className="theme__box theme__box--small theme__box--column about">
          <div className="container">
            <h3>{intro}</h3>
          </div>   
          <Img fluid={image.childImageSharp.fluid} />
          <div className="container">
            <p>{description}</p>
            <Link className="btn" to="/menus">
              Our Menus
            </Link>
            <Link className="btn" to="/contact">
              Get in touch
            </Link>
          </div>   
        </article>  
      </div>
    </div>
  </>
 )

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>    
      <Helmet>
        <meta charSet="utf-8" />
        <title>{frontmatter.meta.metaTitle}</title>
        <meta name="description" content={frontmatter.meta.metaDescription} />
      </Helmet>
      <Layout>
        <AboutPageTemplate
          heroImage={frontmatter.heroImage}
          title={frontmatter.title}
          intro={frontmatter.intro}
          description={frontmatter.description}
          image={frontmatter.image}
        />
      </Layout>
    </>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
        title
        intro
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        meta{
          metaTitle
          metaDescription
        }
      }
    }
  }
`