import React from 'react'
import BackgroundImage from 'gatsby-background-image'

class PageHero extends React.Component {
    render() {
        return <section className="pageHero">
            <div className="container">
                <h1>{this.props.Title}</h1>
            </div>
            <div class="backgroundContainer">
                <BackgroundImage Tag="span"
                    fluid={this.props.Image.childImageSharp.fluid}
                    critical={true}
                ></BackgroundImage>
            </div>
        </section>
    }
}

export default PageHero;